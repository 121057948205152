import { get, set } from "lodash";

export function oneOf(
  key: string,
  value: string | undefined,
  validList: string[]
) {
  if (!value) return true;
  for (let i = 0; i < validList.length; i++) {
    if (value === validList[i]) {
      return true;
    }
  }
  console.error(`${key} must one of: ${validList.join(" | ")}`);
  return false;
}

export function required(key: string, value: any) {
  if (value) return true;
  console.error(`${key} is required!`);
  return false;
}

export const safeObj = (obj: { [key: string]: any }) => {
  const getVals = (keys: string[], valDefault?: any[]) => {
    const objNew = {} as any;
    for (let i = 0; i < keys.length; i++) {
      let val;
      if (!valDefault || valDefault[i] === undefined) val = get(obj, keys[i]);
      else val = get(obj, keys[i], valDefault[i]);
      set(objNew, keys[i], val);
    }
    return objNew;
  };
  const getVal = (key: string, valDefault?: string) => {
    return get(obj, key, valDefault);
  };

  return { getVals, getVal };
};
