
import { defineComponent } from "vue";
import { oneOf } from "@/uikit/_utils/assist";
export default defineComponent({
  name: "Link",
  props: ["type", "underline", "disabled", "href"],
  setup(props) {
    const validator = oneOf("<Button> type", props.type, [
      "primary",
      "info",
      "success",
      "warning",
      "danger"
    ]);
    if (!validator) return;

    return {};
  }
});
