import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 1,
  class: "el-link--inner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", _mergeProps({
    class: [
      'el-link',
      _ctx.type ? `el-link--${_ctx.type}` : '',
      _ctx.disabled && 'is-disabled',
      _ctx.underline && !_ctx.disabled && 'is-underline'
    ],
    href: _ctx.disabled ? null : _ctx.href
  }, _ctx.$attrs, {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }), [
    (_ctx.$slots.iconBefore)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.$slots.iconBefore)
            ? _renderSlot(_ctx.$slots, "iconBefore", { key: 0 })
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots.iconAfter)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.$slots.iconAfter)
            ? _renderSlot(_ctx.$slots, "iconAfter", { key: 0 })
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 16, _hoisted_1))
}